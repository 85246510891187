import React from 'react'
import PropTypes from 'prop-types'

import Loader from '../../Loader'
import './styles.scss'

/**
 * A component to ratings form
 * @param {*} props 
 */
const NegativeFeedbackCard = (props) => {
  const {
    loading, 
    feedbackText,
    store,
    onFeedbackTextChange,
    goBackToReview,
    onFeedbackSubmit
  } = props

  const onSubmit = () => {
    if(!feedbackText) return
    onFeedbackSubmit()
  }

  const pageData = store.thanks_page.negative
  return (
    <div className='eg-negative-feedback'>
      <div className='arrow-back' onClick={goBackToReview}/>
      {
        !!store.rating_logo && <img src={store.rating_logo} className='logo' alt='store' />
      }
      <div className='title'>
        {pageData.headline}
      </div>
      <textarea
        className='feedback-input'
        type='textarea'
        onChange= {onFeedbackTextChange}
        placeholder={pageData.placeholder || 'Tell us what we can improve'}
      />
      {loading 
        ?  <Loader />
        : <div className={`submit ${!feedbackText && 'disabled' }` } onClick={onSubmit}>SUBMIT</div>
      }
    </div>
  )
}

NegativeFeedbackCard.propTypes = {
  store: PropTypes.object.isRequired,
  loading: PropTypes.bool, 
  feedbackText: PropTypes.string,
  source: PropTypes.string,
  onFeedbackTextChange: PropTypes.func,
  goBackToReview: PropTypes.func,
  onFeedbackSubmit: PropTypes.func
}

export default NegativeFeedbackCard
