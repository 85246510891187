import React, { useState, useEffect } from 'react'
import querystring from 'query-string'
import { globalHistory } from '@reach/router'
import { toast } from 'react-toastify'

import Review from './Review'
import NegativeFeedbackCard from './NegativeFeedbackCard'
import FeedbackSucccessCard from './FeebackSuccessCard'
import SuccessCard from './SuccessCard'
import useFetch from '../../hooks/useFetch'
import Loader from '../Loader'
import './styles.scss'

const REVIEW_SECTION = {
  REVIEW: 'review',
  NEGATIVE_FEEDBACK: 'negativeFeedback',
  POSITIVE_FEEDBACK: 'positiveFeedback',
  FEEDBACK_SUBMITTED: 'feedbackSubmitted',
}

const showError = () => {
  toast && toast.error('Sorry, Something went wrong.:( Please try again.',
    {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  )
}

const GenericFeedback = props => {
  const [rating, setRating] = React.useState(0)
  const { search } = globalHistory.location
  const queryParameterObj = querystring.parse(search)
  const { customer_id, store_uuid } = queryParameterObj
  const [screen, setScreen] = useState(REVIEW_SECTION.REVIEW)
  const [ storeData, setStoreData ] = useState(null)
  const [ feedbackText, setFeedbackText ] = useState(null)
  const [ nextScreen, setNextScreen ] = useState(null)
  const [ requestState, callApi ] = useFetch()
  const [ storeApiState, getStoreData ] = useFetch()
  const { loading } = requestState


  useEffect(() => {
    getStoreData(`${process.env.GATSBY_INTEGRATION_URL}/restaurants/${store_uuid}/rating`)
  }, [getStoreData, store_uuid])

  useEffect(() => {       
    const { response, error } = storeApiState
    // When getting store data first time
    if(response) {
      setStoreData(response)
    }
    if(error){
      setStoreData({error})
      showError()
    }
  }, [storeApiState])
  
  useEffect(() => {       
    const { response, error } = requestState
    if(response) {
      setScreen(nextScreen)
    }
    if(error){
      showError()
    }
  }, [nextScreen, requestState])
  

  const sendRatingAndReview = (nextScreen) => {
    setNextScreen(nextScreen)
    const payloadData = {
      customer_id,
      store_uuid,
      rating
    }
    
    if(feedbackText) {
      payloadData.comment = feedbackText
    }
    const url = `${process.env.GATSBY_ZSF_BASE_URL}/rating`
    const options = {
      method: 'POST',
      body: JSON.stringify(payloadData),
      headers: {
        'Content-Type': 'application/json'
      }
    }
    callApi(url, options)
  }

  function onRatingSubmit(data) {
    if(!rating) return 
    if(rating <= 3) {
      return setScreen(REVIEW_SECTION.NEGATIVE_FEEDBACK)
    } else {
      sendRatingAndReview(REVIEW_SECTION.POSITIVE_FEEDBACK)
    }
  }

  const onFeedbackTextChange = (event) => {
    event.preventDefault()
    setFeedbackText(event.target.value)
  }

  const onFeedbackSubmit = () => {
    sendRatingAndReview(REVIEW_SECTION.FEEDBACK_SUBMITTED)
  }

  const goBackToReview = () => {
    setScreen(REVIEW_SECTION.REVIEW)
  }

  const renderReviewForm = () => {
    if(screen === REVIEW_SECTION.REVIEW) {
      return ( 
        <Review
          loading = { loading }
          rating = { rating }
          store={ storeData }
          setRating = {setRating}
          onRatingSubmit = {onRatingSubmit}
        />
      )
    }
    if(screen === REVIEW_SECTION.NEGATIVE_FEEDBACK){
      return (
        <NegativeFeedbackCard
          loading = {loading}
          feedbackText = {feedbackText}
          store={ storeData }
          onFeedbackTextChange = {onFeedbackTextChange}
          goBackToReview = {goBackToReview}
          onFeedbackSubmit = {onFeedbackSubmit}
        />
      )
    }

    if(screen === REVIEW_SECTION.POSITIVE_FEEDBACK) {
      return <SuccessCard storeUuid={store_uuid} store={ storeData }/>
    }

    if(screen === REVIEW_SECTION.FEEDBACK_SUBMITTED) {
      return <FeedbackSucccessCard store={ storeData }/>
    }
  }

  if(!storeData){
    return <Loader className='eg-loyalty-loader' />
  }

  if(storeData.error){
    return (
      <div className='zsf-feedback'>
        Something went wrong, please try refreshing the song.
      </div>
    )
  }

  return (
    <div className='zsf-feedback'>
      { renderReviewForm() }
      <div className='powered-by'>Powered by <a href='/'> ZeroStorefront </a></div>
    </div>
  )
}

export default GenericFeedback
