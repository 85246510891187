import React from 'react'
import PropTypes from 'prop-types'
import StarRatings from 'react-star-ratings'

import Loader from '../../Loader'
import './styles.scss'


/**
 * A component to ratings form
 * @param {*} props 
 */
const Review = (props) => {
  const {
    loading, 
    rating,
    store = {},
    setRating,
    onRatingSubmit
  } = props

  const ratingPageData = store.rating_page
  return (
    <div className='zsf-review'>
      {
        !!store.rating_logo && <img src={store.rating_logo} className='logo' alt='store' />
      }
      <div className='title'>
        {ratingPageData && ratingPageData.headline}
      </div>
      <div className='subtitle'>
        {ratingPageData && ratingPageData.subtext}
      </div>
      <StarRatings
        rating={rating}
        starRatedColor="#fac723"
        changeRating={(newRating) => setRating(newRating)}
        numberOfStars={5}
        name='rating'
      />
      {loading 
        ?  <Loader />
        : <div className={`submit ${!rating && 'disabled' }` } onClick={onRatingSubmit}>SUBMIT</div>
      }
          
    </div>
  )
}

Review.propTypes = {
  store: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  rating: PropTypes.number,
  source: PropTypes.string,
  setRating: PropTypes.func,
  onRatingSubmit: PropTypes.func,
}

export default Review
